import './App.css';
import React, { useRef, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import { Col, Row, Space, Select, Card, Image, List, Spin, Button, InputNumber } from 'antd';
import { Link  } from "react-router-dom";

const { Meta } = Card;
const MAX_LENGTH = 200;

function CheckQuality() {
  const validColor = 'rgb(7 203 25)';
  const errorColor = 'rgb(230 6 6)';
  const webcamRef = useRef(null);
  let count_frame = 0;
  let lastFrameTime = 0;
  const frameInterval = 1000 / 5; // 3 frames per second
  const [direction, setDirection] = useState('up');
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState(errorColor);
  const [validImage, setValidImage] = useState([]);
  const [inValidImage, setInValidImage] = useState([]);
  const [toggle, setToggle] = useState(1);
  const [toggleText, setToggleText] = useState("Stop");
  const [imageWidth, setImageWidth] = useState(1080);
  const [imageHeight, setImageHeight] = useState(1920);
  

  const beHost = process.env.REACT_APP_BE_HOST;
  const bePort = process.env.REACT_APP_BE_PORT;
  const beApiKey = process.env.REACT_APP_BE_API_KEY;
  // const checkQualityUrl = beHost + ':' + bePort + '/api/faces/check-quality' ;
  const checkQualityUrl = 'https://viettin.camera.boot.ai/be/api/faces/check-quality' ;

  // Function to capture frames from the webcam
  useEffect(() => {
    localStorage.setItem('head_pose', direction);
  }, [direction]);

  useEffect(() => {
    localStorage.setItem('toggle', toggle);
    localStorage.setItem('image_width', imageWidth);
    localStorage.setItem('image_height', imageHeight);
  }, []);


  const handleToggle = () => {
    setToggleText(toggle===0?"Stop":"Start");
    localStorage.setItem('toggle', toggle===1?0:1);
    setToggle(toggle===1?0:1);
  }

  const onChangeImageWidth = (value) => {
    setImageWidth(value);
  };

  const onChangeImageHeight = (value) => {
    setImageHeight(value);
  };

  useEffect(() => {
    localStorage.setItem('image_width', imageWidth);
  }, [imageWidth]);
  
  useEffect(() => {
    localStorage.setItem('image_height', imageHeight);
  }, [imageHeight]);

  useEffect(() => {
    localStorage.setItem('amount_valid_image', validImage.length);
  }, [validImage]);

  useEffect(() => {
    localStorage.setItem('amount_invalid_image', inValidImage.length);
  }, [inValidImage]);

  const captureFrame = async () => {
    const toggle = localStorage.getItem('toggle');
    const imageWidthLs = parseInt(localStorage.getItem('image_width'));
    const amountValidImageLs = parseInt(localStorage.getItem('amount_valid_image'));
    const amountInvalidImageLs = parseInt(localStorage.getItem('amount_invalid_image'));
    const imageHeightLs = parseInt(localStorage.getItem('image_height'));
    const currentTime = Date.now();
    const elapsedTime = currentTime - lastFrameTime;
    if (toggle === '0' || toggle === 0) 
    {
      setMessageColor(validColor);
      setMessage("Tracking stopped");
      
      if (elapsedTime >= frameInterval) {
        let imageSrc = '';
        if (webcamRef.current != null) {
          imageSrc = webcamRef.current.getScreenshot({width: imageWidthLs, height: imageHeightLs});
        }
      }
      lastFrameTime = currentTime;
    }
    else
    {
      if (elapsedTime >= frameInterval) {
        let imageSrc = '';
        if (webcamRef.current != null) {
          imageSrc = webcamRef.current.getScreenshot({width: imageWidthLs, height: imageHeightLs});
        }
        const directionStored = localStorage.getItem('head_pose');

        count_frame += 1;

        lastFrameTime = currentTime;

        // Handle image
        const blobImage = await fetch(imageSrc).then((res) => res.blob());  // Convert the base64 image to a Blob
        const formData = new FormData();
        formData.append('image', blobImage, 'image.jpg');  // 'image' is the name of the field, 'image.jpg' is the filename
        formData.append('direction', directionStored);
        
        // Call API
        try {
          // Call the REST API using fetch
          const response = await fetch(checkQualityUrl, {
            method: 'POST',
            body: formData,
            headers: {Authorization: beApiKey}
          });
    
          // Check if the response is successful
          if (response.ok) {
            const data = await response.json();
            console.log('Response data:', data);
            if (data['status'] === true) {
                const status = data['data']['result'];
                const head_pose = data['data']['direction'];
                const currentDate = new Date();

                // Get the current date and time components
                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1; // Month is zero-based, so we add 1
                const day = currentDate.getDate();
                const hours = currentDate.getHours();
                const minutes = currentDate.getMinutes();
                const seconds = currentDate.getSeconds();

                // Format the date and time as a string
                const currentDateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                if (status === true) {
                  const newImage = {
                    'head_pose': head_pose,
                    'image': imageSrc,
                    'tracked_date': currentDateTimeString
                  };
                  setMessageColor(validColor);
                  if (amountValidImageLs < MAX_LENGTH) {
                    setValidImage(validImage => [newImage, ...validImage]);
                  }
                }
                else {
                  setMessageColor(errorColor);
                  if (data['message'] !== 'Wrong head pose.' && data['message'] !== 'Failed when check quality.') {
                    const newImage = {
                      'head_pose': head_pose,
                      'image': imageSrc,
                      'tracked_date': currentDateTimeString,
                      'error': data['message']
                    };
                    if (amountInvalidImageLs < MAX_LENGTH) {
                      setInValidImage(inValidImage => [newImage, ...inValidImage]);
                    }
                  }
                }
            }
            else {
              setMessageColor(errorColor);
            }
            setMessage(data['message']);
          } else {
            setMessageColor(errorColor);
            setMessage("Connection interrupted");
            // pass
          }
        } catch (error) {
          console.error('Error:', error);
          setMessageColor(errorColor);
          setMessage("Connection interrupted");
        }
      }
    }
    requestAnimationFrame(captureFrame);

    // const imageSrc = webcamRef.current.getScreenshot();
    // You can process the image source here, such as sending it to a server, etc.
  };

  // Start capturing frames when the component mounts
  useEffect(() => {
    if (webcamRef.current) {
      requestAnimationFrame(captureFrame);
    }
  }, []);

  const options = [
    {
      value: 'up',
      label: 'up',
    },
    {
      value: 'down',
      label: 'down',
    },
    {
      value: 'left',
      label: 'left',
    },
    {
      value: 'right',
      label: 'right',
    },
    {
      value: 'ahead',
      label: 'ahead',
    },
    {
      value: 'top_left',
      label: 'top_left',
    },
    {
      value: 'top_right',
      label: 'top_right',
    },
  ];

  return (
    <div className="App">
      
      <header style={{paddingBottom: '32px'}}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h1 style={{marginRight: '16px'}}>Checking <span style={{color: messageColor}}>{direction}</span> head pose </h1>
          <Spin
            spinning={true?messageColor===errorColor:false}
          />
        </div>
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <Link to="/recognize" className="btn btn-primary">Go to recognize page</Link>
          <Link to="/check-quality-batch" className="btn btn-primary">Go to check quality by batch</Link>
        </div>
        
      </header>
      <body>
        <div style={{backgroundColor: '#e7e7e748'}}>
          <Row wrap={true}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <div style={{ marginLeft: 48, marginRight: 48}}>
                <List
                  header={<div style={{fontSize: '16px', fontWeight: 'bold', color: validColor}}>Valid images: {validImage.length}
                    <p style={{color: '#555555', fontSize: '12px', marginTop: '0', marginBottom: '0', fontWeight: 'normal', fontStyle: 'italic'}}>Handle max {MAX_LENGTH} images</p>
                  </div>}
                  bordered
                  dataSource={validImage}
                  renderItem={(item) => (
                    <List.Item>
                      <Card
                        hoverable
                        style={{ width: 240 }}
                        cover={
                        <Image
                          width={240}
                          src={item['image']}
                          placeholder={
                            <Image
                              preview={false}
                              src={item['image']}
                              width={480}
                            />
                          }
                        />
                      }
                      >
                        <Meta title={item['head_pose']} description={'Tracked_date: ' + item['tracked_date']} />
                    </Card>
                    </List.Item>
                )}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column', marginBottom: 48}}>
              <Space style={{marginBottom: 12}}>
                <span style={{fontSize: '16px', fontWeight: 'bold', color: '#434343'}}>Image Width</span>
                <InputNumber min={10} max={5000} defaultValue={imageWidth} onChange={onChangeImageWidth} />
              </Space>
              <Space>
                <span style={{fontSize: '16px', fontWeight: 'bold', color: '#434343'}}>Image Height</span>
                <InputNumber min={10} max={5000} defaultValue={imageHeight} onChange={onChangeImageHeight} />
              </Space>
              <Space>
                <p style={{fontSize: '16px', fontWeight: 'bold', color: '#434343'}}>Choose the head pose</p>
                <Space.Compact style={{padding: 0}}>
                  <Select defaultValue="up" options={options} onChange={(value) => {
                    localStorage.setItem('head_pose', value.toString());
                    setDirection(value.toString());
                  }}/>
                </Space.Compact>
              </Space>

              <div style={{'width': '60%', display: 'flex', justifyContent: 'center', flexDirection: 'column',
                            alignItems: 'center'}}>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  mirrored={false}
                  width="100%"
                  imageSmoothing={false}
                  style={{"borderRadius": 20, marginBottom: 24}}
                  videoConstraints={{facingMode: 'user', aspectRatio: imageWidth/imageHeight}}
                />

                <h2 style={{color: messageColor, marginBottom: '24', marginTop: '0'}}>{message} </h2>
                <Button type="primary" danger={toggle===1?true:false} style={{width: 80}} 
                  onClick={() => {handleToggle();}}>
                  {toggleText}
                </Button>
              </div>
              
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <div style={{ marginRight: 48, marginLeft: 48}}>
                <List
                  header={<div style={{fontSize: '16px', fontWeight: 'bold', color: errorColor}}>Invalid images: {inValidImage.length}
                    <p style={{color: '#555555', fontSize: '12px', marginTop: '0', marginBottom: '0', fontWeight: 'normal', fontStyle: 'italic'}}>Handle max {MAX_LENGTH} images</p>
                    <p style={{color: '#555555', fontSize: '12px', marginTop: '0', marginBottom: '0', fontWeight: 'normal', fontStyle: 'italic'}}>(without Wrong head pose)</p>
                  </div>}
                  bordered
                  dataSource={inValidImage}
                  renderItem={(item) => (
                    <List.Item>
                      <Card
                        hoverable
                        style={{ width: 240 }}
                        cover={
                        <Image
                          width={240}
                          src={item['image']}
                          placeholder={
                            <Image
                              preview={false}
                              src={item['image']}
                              width={480}
                            />
                          }
                        />
                      }
                      >
                        <Meta title={item['head_pose']} description={'Error: ' + item['error']} />
                    </Card>
                    </List.Item>
                )}
                />
              </div>
            </Col>
            
          </Row>
          
        </div>
      </body>

    </div>
  
  );
}

export default CheckQuality;

