import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CheckQuality from "./CheckQuality";
import Recognize from "./Recognize";
import CheckQualityBatch from "./CheckQualityBatch";

const Home = () => <h1>Home</h1>;

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CheckQuality />}>
          </Route>
        <Route path="/check-quality" element={<CheckQuality />}>
        </Route>
        <Route path="/recognize" element={<Recognize />}>
        </Route>
        <Route path="/check-quality-batch" element={<CheckQualityBatch />}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default App;
